import { electionsDebug } from '../../../__helpers/elections-debug'
import {
    ElectionSortFilterOptions,
    ElectionStateFilterOptions,
    sortMethods,
} from '../../../contexts'
import { SeatData } from './election-data'
import {
    ExtendedInterknowlogyArea,
    ExtendedInterknowlogyData,
    ExtendedInterknowlogyParty,
} from './getInterknowlogyData'

/** Filters the provided Parties based on the provided filter param.
 * i.e. passing in a filter of `WA` would only return areas with an `areacode` of WA.
 */
export const filterPartiesByState = (
    parties: ExtendedInterknowlogyParty[],
    filter: ElectionStateFilterOptions[number],
): ExtendedInterknowlogyParty[] => {
    return filter.displayName === 'All' || filter.displayName === ''
        ? parties
        : parties.filter(
              (party) => party.state.toLowerCase() === filter.paramName,
          )
}

export const filterAreasByState = (
    areas: ExtendedInterknowlogyArea[],
    filter: ElectionStateFilterOptions[number],
): ExtendedInterknowlogyArea[] => {
    return filter.displayName === 'All'
        ? areas
        : areas.filter(
              (area) => area.areaCode.toLowerCase() === filter.paramName,
          )
}
/** Filters the provided Seats based on the provided filter param.
 * i.e. passing in a filter of `WA` would only return areas with an `areacode` of WA.
 */
export const filterSeatsByState = (
    seats: SeatData[],
    filter: ElectionStateFilterOptions[number],
): SeatData[] => {
    return filter.displayName === 'All'
        ? seats
        : seats.filter((seat) => seat.state.toLowerCase() === filter.paramName)
}

/** Searches the provided array of seats for matches on `SeatName`, `CandidateName` or `Postcode` */
export const searchSeatsOrCandidates = (
    seats: SeatData[],
    searchTerm: string,
): SeatData[] => {
    const postcodeMatches = []
    const seatNameMatches = []
    const candidateMatches = []

    for (const seat of seats) {
        // Check postcode first.
        if (seat.postcodes.includes(parseInt(searchTerm))) {
            postcodeMatches.push(seat)
        } else if (
            // Otherwise check seatName
            seat.seatName.toLowerCase().includes(searchTerm.toLowerCase())
        ) {
            seatNameMatches.push(seat)
        } else if (
            // Finally check if a candidate matches.
            seat.allCandidates.some((candidate) =>
                candidate.candidateName
                    .toLowerCase()
                    .includes(searchTerm.toLowerCase()),
            )
        ) {
            candidateMatches.push(seat)
        } else if (
            //check if a tpp candidate matches
            seat.candidates.some((candidate) =>
                candidate.candidateName
                    .toLowerCase()
                    .includes(searchTerm.toLowerCase()),
            )
        ) {
            candidateMatches.push(seat)
        }
        electionsDebug(
            'search filter states',
            postcodeMatches,
            seatNameMatches,
            candidateMatches,
        )
    }

    return [...postcodeMatches, ...seatNameMatches, ...candidateMatches]
}

/** Sorts the provided array of seats based on the provided sort method */
export const sortSeats = (
    seats: SeatData[] | undefined,
    sort?: ElectionSortFilterOptions[number],
) => {
    // If no sort method is provided, default to latest
    if (!sort) {
        sort = sortMethods[0]
    }

    const definedSort = sort
    // no sort provided still some how..., just return seats
    if (!definedSort) {
        electionsDebug('No sort method provided, returning seats unsorted')
        return seats
    }

    const sortedSeats = seats
        ? [...seats].sort((a, b) => {
              if (definedSort.paramName === 'a-z') {
                  return a.seatName.localeCompare(b.seatName)
              } else if (definedSort.paramName === 'z-a') {
                  return b.seatName.localeCompare(a.seatName)
              } else if (definedSort.paramName === 'latest') {
                  switch (a.lastUpdated) {
                      case null:
                      case 'null': {
                          if (
                              b.lastUpdated === null ||
                              b.lastUpdated === 'null'
                          ) {
                              return 0
                          } // can't sort nulls
                          return 1 // any value is more recent than null
                      }
                      case b.lastUpdated:
                          return 0 // same updated date, no change
                      default: {
                          if (!b.lastUpdated || b.lastUpdated == 'null') {
                              return -1 // any value is more recent than null
                          }
                          // Sort by date, most recent first
                          return a.lastUpdated > b.lastUpdated ? -1 : 1
                      }
                  }
              } else return 0 // Default
          })
        : undefined
    electionsDebug('sorted seats', sortedSeats)
    return sortedSeats
}
